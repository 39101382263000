@font-face {
  font-family: "Causten Round";
  src: url(fonts/Causten-Round-Black.ttf);
  font-weight: 900;
}
@font-face {
  font-family: "Causten Round";
  src: url(fonts/Causten-Round-Extra-Bold.ttf);
  font-weight: 800;
}
@font-face {
  font-family: "Causten Round";
  src: url(fonts/Causten-Round-Semi-Bold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: "Causten Round";
  src: url(fonts/Causten-Round-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "Causten Round";
  src: url(fonts/Causten-Round-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "Interphases Pro";
  src: url(fonts/interphasesPro/TT-Interphases-Pro-DemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: "Interphases Pro";
  src: url(fonts/interphasesPro/TT-Interphases-Pro-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "Interphases Pro";
  src: url(fonts/interphasesPro/TT-Interphases-Pro-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "Tiempos Headline";
  src: url(fonts/TiemposText-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "ATB TT Norms";
  src: url(fonts/organizations/ATB/TT-Norms-TrueType/ATB-TT-Norms-Black.ttf);
  font-weight: 900;
}
@font-face {
  font-family: "ATB TT Norms";
  src: url(fonts/organizations/ATB/TT-Norms-TrueType/ATB-TT-Norms-ExtraBold.ttf);
  font-weight: 800;
}
@font-face {
  font-family: "ATB TT Norms";
  src: url(fonts/organizations/ATB/TT-Norms-TrueType/ATB-TT-Norms-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "ATB TT Norms";
  src: url(fonts/organizations/ATB/TT-Norms-TrueType/ATB-TT-Norms-DemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: "ATB TT Norms";
  src: url(fonts/organizations/ATB/TT-Norms-TrueType/ATB-TT-Norms-Regular.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "ATB TT Norms";
  src: url(fonts/organizations/ATB/TT-Norms-TrueType/ATB-TT-Norms-Normal.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "Inter";
  src: url(fonts/organizations/ATB/Inter/Inter.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "Poppins Black";
  src: url(fonts/organizations/CST/Poppins/Poppins-Black.ttf);
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Poppins Black Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-BlackItalic.ttf);
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Poppins Bold";
  src: url(fonts/organizations/CST/Poppins/Poppins-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Poppins Bold Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-BoldItalic.ttf);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Poppins ExtraBold";
  src: url(fonts/organizations/CST/Poppins/Poppins-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Poppins ExtraBold Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Poppins ExtraLight";
  src: url(fonts/organizations/CST/Poppins/Poppins-ExtraLight.ttf);
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Poppins ExtraLight Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-ExtraLightItalic.ttf);
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Poppins Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-Italic.ttf);
  font-weight: 400; /* Typically regular weight */
  font-style: italic;
}

@font-face {
  font-family: "Poppins Light";
  src: url(fonts/organizations/CST/Poppins/Poppins-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins Light Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Poppins Medium";
  src: url(fonts/organizations/CST/Poppins/Poppins-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins Medium Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Poppins Regular";
  src: url(fonts/organizations/CST/Poppins/Poppins-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url(fonts/organizations/CST/Poppins/Poppins-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins SemiBold Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Poppins Thin";
  src: url(fonts/organizations/CST/Poppins/Poppins-Thin.ttf);
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Poppins Thin Italic";
  src: url(fonts/organizations/CST/Poppins/Poppins-ThinItalic.ttf);
  font-weight: 100;
  font-style: italic;
}
