.MuiToggleButtonGroup-root {
  padding: 0.25em;
  background-color: rgba(247,247,247);
}
.MuiToggleButtonGroup-root .Mui-selected {
  background-color: white !important;
}
.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
  min-width: 15em;
  border: 0 !important;
  text-transform: none !important;
  font-weight: bold !important;
  padding: 0.25em !important;
}
